import React, {lazy, Suspense} from 'react';
import withStore from "@/hocs/withStore";
import {isDesktop} from "@/Helpers/Function";
import Svg from "@/Pages/All/Svg";
import "@/Pages/Catalog/scss/index.scss";

const AddSnackbars = lazy(() => import('@/Pages/All/AddSnackbars'));
const SelectCityModal = lazy(() => import('@/Pages/Header/SelectCityModal'));
const RightMenu = lazy(() => import('@/Pages/Header/RightMenu'));
const SelectSize = lazy(() => import('@/Pages/All/SelectSize'));
const NotSize = lazy(() => import('@/Pages/All/NotSize'));
const SuccessAddWaitList = lazy(() => import('@/Pages/All/SuccessAddWaitList'));
const FindYourSize = lazy(() => import('@/Pages/All/FindYourSize'));
const Entrance = lazy(() => import('@/Pages/All/Entrance'));
const ServiceModal = lazy(() => import('@/Pages/All/ServiceModal'));
const Widget = lazy(() => import('@/Pages/All/Widget'));
const BuyOneClick = lazy(() => import('@/Pages/All/BuyOneClick'));
const QuickView = lazy(() => import('@/Pages/All/QuickView'));
const DeliveryInformationModal = lazy(() => import('@/Pages/All/DeliveryInformationModal'));
const ModalError = lazy(() => import('@/Pages/All/ModalError'));
const FindOutPriceModal = lazy(() => import('@/Pages/All/FindOutPriceModal'));
const CreatingPreOrderModal = lazy(() => import('@/Pages/All/CreatingPreOrderModal'));
const WheelOfFortuneModal = lazy(() => import('@/Pages/All/WheelOfFortuneModal'));
const WidgetModal = lazy(() => import('@/Components/Modal/WidgetModal'));
const ApplicationDrawer = lazy(() => import('@/Components/Modal/ApplicationDrawer'));

const ModalWindowsComponent = (props) => {
    const {stores = {}} = props;
    const {allStore = {}} = stores;
    return <>
        {isDesktop() && (
            <button id={`backToTop`} title={`Back to top`} className={`scrollTop`}
                    onClick={() => {
                        window.scrollTo(0, 0);
                    }}>
                    <span className={`arrow-up`}>
                       <Svg id={`arrowUpwardIcon`} className={`svg__arrowUpwardIcon`} />
                    </span>
            </button>
        )}
        {allStore.interactionFlag && (
            <>
                <Suspense><AddSnackbars {...props} /></Suspense>
                <Suspense><SelectCityModal {...props} /></Suspense>
                <Suspense><Widget {...props} /></Suspense>
                <Suspense><RightMenu {...props} /></Suspense>
                <Suspense><BuyOneClick {...props} /></Suspense>
                <Suspense><QuickView {...props} /></Suspense>
                <Suspense><SelectSize {...props} /></Suspense>
                <Suspense><NotSize {...props} /></Suspense>
                <Suspense><SuccessAddWaitList {...props} /></Suspense>
                <Suspense><FindYourSize {...props} /></Suspense>
                <Suspense><Entrance {...props} /></Suspense>
                <Suspense><DeliveryInformationModal {...props} /></Suspense>
                <Suspense><ServiceModal {...props} /></Suspense>
                <Suspense><ModalError {...props} /></Suspense>
                <Suspense><FindOutPriceModal {...props} /></Suspense>
                <Suspense><CreatingPreOrderModal {...props} /></Suspense>
                <Suspense><WheelOfFortuneModal {...props} /></Suspense>
                <Suspense><WidgetModal {...props} /></Suspense>
                <Suspense><ApplicationDrawer {...props} /></Suspense>
            </>
        )}
    </>
};

export default withStore(ModalWindowsComponent);
